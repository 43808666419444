import Base64CentralWrapper from "@jordibosch20/software_tools_package/dist/pages/Base64CentralWrapper.component.js"
import styles from "./App.module.css";
import { getBase64String } from "@jordibosch20/software_tools_package/dist/utils/base64.js";
import { useAnalytics } from './useAnalytics';

function App() {
  useAnalytics();
  return (
    <div className={styles.AppWrapper}>
      <div className={styles.App}>
        <Base64CentralWrapper title={"Convert to Base64"} f={getBase64String}></Base64CentralWrapper>
      </div>
      <div className={styles.text}>
        <div class="flex-1 overflow-hidden">
        <div class="react-scroll-to-bottom--css-ccwcr-79elbk h-full">
        <div class="react-scroll-to-bottom--css-ccwcr-1n7m0yu">
        <div class="flex flex-col pb-9 text-sm">
        <div class="w-full text-token-text-primary" data-testid="conversation-turn-9">
        <div class="px-4 py-2 justify-center text-base md:gap-6 m-auto">
        <div class="flex flex-1 text-base mx-auto gap-3 md:px-5 lg:px-1 xl:px-5 md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem] group final-completion">
        <div class="relative flex w-full flex-col agent-turn">
        <div class="flex-col gap-1 md:gap-3">
        <div class="flex flex-grow flex-col max-w-full">
        <div data-message-author-role="assistant" data-message-id="f96e419c-d380-4a0d-b28b-ad2ae6eb6396" class="min-h-[20px] text-message flex flex-col items-start gap-3 whitespace-pre-wrap break-words [.text-message+&amp;]:mt-5 overflow-x-auto">
        <div class="markdown prose w-full break-words dark:prose-invert light">
        <h3>Introduction</h3>
        <p>In the realm of data and web development, converting information into a Base64 format is a common practice, allowing binary data to be sent over channels that are designed to deal with text like email or URL appended parameters.</p>
        <p>This encoding and decoding process can sometimes be tedious, especially when dealing with various character encodings or large files. That's where our tool comes in, offering a one-stop solution for all your Base64 conversion needs, whether it's text or files you're working with. With support for multiple encodings, including UTF-8 and UTF-16, UTF-32, both little and big endian, our online tool ensures your conversions are accurate, and efficient.</p>
        <h3>Why Use Base64 Encoding?</h3>
        <p>Base64 encoding is invaluable for embedding image files or other binary assets directly into HTML, CSS files, or various scripting languages. It converts binary data into a text format, which can be easily transmitted and decoded back into the original binary, ensuring the integrity of the data remains intact. This process is crucial for web developers, programmers, and anyone needing to encode sensitive information for safe transmission over the internet.</p>
        <h3>Features of Our Base64 Encoder/Decoder</h3>
        <p>Our tool is designed to be versatile, user-friendly, and efficient, making it the perfect choice for both professionals and hobbyists. Here are some of the standout features:</p>
        <ul>
        <li><strong>Multiple Encoding Support:</strong> Whether your data is in UTF-8, UTF-16, UTF-32, ISO-8859 or another character set, our tool can handle it, providing accurate encoding and decoding.</li>
        <li><strong>Text and File Conversion:</strong> Easily convert both plain text and files to and from Base64, making it a versatile tool for various types of data.</li>
        <li><strong>Instant Results:</strong> Our algorithms ensure that your data is converted swiftly, allowing for a smooth and fast workflow.</li>
        <li><strong>User-Friendly Interface:</strong> With a straightforward design, our tool is accessible to users of all skill levels, ensuring a hassle-free conversion process.</li>
        <li><strong>Secure and Private:</strong> We prioritize your privacy and security. Uploaded files and entered text are processed on the fly without being stored or monitored. All code is executed on client side, thus, our servers never see your text/files. You can be sure, there is no data leakage</li>
        </ul>
        <h3>How to Use the Base64 Encoder/Decoder</h3>
        <ol>
        <li><strong>Choose Your Encoding:</strong> Select the character encoding of your input data, such as UTF-8 or UTF-16.</li>
        <li><strong>Input Your Data:</strong> Paste your text into the input field or upload the file you wish to convert.</li>
        <li><strong>Select Your Action:</strong> Choose whether you want to encode or decode your data.</li>
        <li><strong>Convert:</strong> With a single click to "check result", your data is converted, and the result is displayed instantly.</li>
        <li><strong>Copy or Download:</strong> Copy the converted text to your clipboard or download the converted file directly to your device.</li>
        </ol>
        <h3>The Importance of Encoding Options</h3>
        <p>Different character encodings, like UTF-8 and UTF-16, serve various purposes and are used in specific contexts to ensure the accurate representation of text in digital formats. By offering support for multiple encodings, our tool ensures that your data is not only converted to or from Base64 but is also correctly encoded or decoded into the character set that best suits your project's needs, preventing data corruption and loss of information.</p>
        <p></p>
        <p><strong>Q: Can I encode/decode large files?</strong><br />A: Yes, our tool can handle large files, but the processing time may vary depending on the file size and your device RAM.</p>
        <p><strong>Q: Is my data safe when using this tool?</strong><br />A: Yes, we prioritize user privacy and data security. Your data is converted in real-time and is not stored or monitored by our servers. Everything is executed on your device and no personal data leaves it.</p>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        <div className={styles.links}>
          <h3>Other similar resoures that could be helpful</h3>

          <li><a href="https://check-json.com">JSON format checker</a></li>
          <li><a href="https://check-xml.com">XML Checker</a></li>
          <li><a href="https://check-yaml.com">YAML Checker</a></li>
          <li><a href="https://formatter-json.com">JSON formatter</a></li>
          <li><a href="https://add-delimiters.com">Add delimiters to sentences</a></li>
          <li><a href="https://convert-base.com">Convert base</a></li>
          <li><a href="https://convert-binary.com">Convert text/file to binary</a></li>
          <li><a href="https://convert-hexadecimal.com">Convert text/file to hexadecimal</a></li>
          <li><a href="https://convert-base64.com">Convert text/file to base64</a></li>
          <li><a href="https://css-beautify.com">CSS beautifier</a></li>
          <li><a href="https://html-beautify.com">HTML beautifier</a></li>
          <li><a href="https://javascript-beautify.com">Javascript beautifier</a></li>
          <li><a href="https://xml-beautify.com">XML beautifier</a></li>
          <li><a href="https://extract-email.com">Email extractor</a></li>
          <li><a href="https://extract-phone.com">Phone extractor</a></li>
          <li><a href="https://extract-url.com">URL extractor</a></li>
          <li><a href="https://json-2-csv.com">JSON to CSV online converter</a></li>
          <li><a href="https://json-to-yaml.com">JSON to YAML online converter</a></li>
          <li><a href="https://software-kit.com">Software tools</a></li>
          <li><a href="https://svg2css.com">SVG to CSS background </a></li>
          <li><a href="https://trim-text.com">Text trimmer</a></li>
          <li><a href="https://url-coder.com">URL encoder/decoder</a></li>
          <li><a href="https://yaml2json.com">YAML to JSON</a></li>
          <li><a href="https://csv-to-json.com">CSV to JSON</a></li>
          <li><a href="https://sort-text.com">Sort text</a></li>
          <li><a href="https://convert-casing.com">Convert casing</a></li>
          <br></br>
          <br></br>
          <br></br>
          <span>In case you have any <b>suggerence or found any bug</b> in our tools, please, feel free to email us at statisticskingdom @ gmail.com (without the spaces) and we will take care of it.</span>
        </div>
      </div>
    </div>
  );
}

export default App;